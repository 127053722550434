<template>
  <v-bottom-navigation
    v-if="$route.path !== '/login' && !$vuetify.breakpoint.mdAndUp && links"
    color="primary"
    app
    v-model="activeBottomNavigation"
    :input-value="$store.app.showBottomNavigation"
  >
    <v-btn
      v-for="link in links"
      :key="`bottom-${link.name}`"
      :to="link.to"
      width="70"
      class="link"
      @click="onClick(link)"
    >
      <span style="font-size: 9px">{{ link.name }}</span>
      <v-icon>{{ link.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'AppBottomNavigation',
  props: {
    linksType: {
      type: String,
      required: false,
      default: 'datasets',
    },
  },
  data: function() {
    return {
      linksData: {
        datasets: [
          {
            name: 'Расчет',
            icon: 'mdi-calculator-variant',
            to: '/pipeline',
          },
          {
            name: 'Регистры',
            icon: 'mdi-book-open-variant',
            to: '/registers',
          },
          {
            name: 'Справочники',
            icon: 'mdi-folder-text',
            to: '/directories',
          },
        ],
        dataset: [
          {
            name: 'Назад',
            icon: 'mdi-arrow-left',
            key: 'back',
          },
          {
            name: 'Описание',
            icon: 'mdi-clipboard-text',
            key: 'main',
          },
          {
            name: 'Данные',
            icon: 'mdi-note-multiple',
            key: 'items',
          },
        ],
        datasetItem: [
          {
            name: 'Назад',
            icon: 'mdi-arrow-left',
            key: 'back',
          },
          {
            name: 'Сохранить',
            icon: 'mdi-content-save',
            key: 'save',
          },
        ],
        back: [
          {
            name: 'Назад',
            icon: 'mdi-arrow-left',
            key: 'back',
          },
        ],
      },
      activeBottomNavigation: null,
    };
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        if (!this.$route.meta?.tab) {
          return;
        }
        const foundIndex = this.links.findIndex(el => el.key === this.$route.meta.tab);
        if (foundIndex === -1) {
          return;
        }
        this.activeBottomNavigation = foundIndex;
      },
    },
  },
  computed: {
    links() {
      return this.linksData[this.linksType];
    },
  },
  methods: {
    onClick(link) {
      if (link.onClick) {
        link.onClick();
      }
      this.$emit('click', link.key);
    },
  },
};
</script>

<style scoped>
@media (max-width: 400px) {
  .link {
    max-width: 64px !important;
    min-width: 64px !important;
    padding: 0 !important;
  }
}
</style>
