<template>
  <v-text-field
      :value="value"
      :rules="rules"
      @change="input"
      @focus="focus"
      outlined
      color="primary"
      dense
      type="number"
      hide-details
      ref="input"
  />
</template>

<script>
export default {
  name: "PeriodYearInput",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    rules() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
        v => (v || '').length === 4 || `Превышено максимальное количество символов`,
        v => !isNaN(parseInt(v)) || 'В поле должно быть целое число',
        v => !!v?.match?.(/^(\d+)$/)?.length || 'В поле должны быть только цифры',
      ];
    },
  },
  methods: {
    input(v) {
      if (!this.$refs.input.valid) {
        return;
      }
      this.$emit('input', v);
    },
    focus() {
      this.$emit('focus');
    },
    isValid() {
      return this.$refs.input.valid;
    },
  },
};
</script>

<style scoped>

</style>
