<template>
  <v-autocomplete
      v-if="items"
      v-model="model"
      :items="items"
      outlined
      dense
      color="primary"
      item-text="НаименованиеСокращённое"
      item-value="КодБЕ"
      @input="change"
      :disabled="disabled"
      hide-details
  >
<!--    <template v-slot:selection="data">-->
<!--      <v-chip-->
<!--          v-bind="data.attrs"-->
<!--          class="body-2"-->
<!--      >-->
<!--        {{ data.item['НаименованиеСокращённое'] }}-->
<!--      </v-chip>-->
<!--    </template>-->
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item" class="body-2"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title class="subtitle-1">{{data.item['НаименованиеСокращённое']}}</v-list-item-title>
          <v-list-item-subtitle class="body-2">Код: {{data.item['КодБЕ']}}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "BizUnitInput",
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    model: null
  }),
  watch: {
    value(newValue) {
      if (newValue) {
        this.model = newValue;
      }
    },
  },
  created () {
    if (this.value) {
      this.model = this.value;
    }
  },
  methods: {
    change (id) {
      this.items.forEach(el => {
        if (el['КодБЕ'] === id) {
          this.$emit('input', el);
        }
      })
    },
  },
};
</script>

<style scoped>

</style>
