import Vue from 'vue';
// import Keycloak from 'keycloak-js';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import createVuetify from '@/plugins/vuetify';
import AppPlugin from '@/utils/AppPlugin';
// import RequestManager from '@/utils/RequestManager';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import './utils/WindowSize';

// init data for app

const plugins = [
  new AppPlugin('store', ['app', 'main', 'persist']),
  new AppPlugin('api', ['auth', 'back', 'app', 'main'], true),
];

Vue.config.productionTip = false;

// for IDE highlighting
Vue.prototype.$api = null;
Vue.prototype.$store = null;

// const initOptions = {
//   url: process.env.VUE_APP_KEYCLOAK_URL,
//   realm: process.env.VUE_APP_KEYCLOAK_REALM,
//   clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
// };

// Vue.prototype.$keycloak = Keycloak(initOptions);

(async function() {

  await Promise.all(plugins.map(async plugin => {
    Vue.use(plugin);
    await plugin.wait();
  }));

  // render app

  const app = new Vue({
    router,
    vuetify: createVuetify({isDark: Vue.prototype.$store.persist.d.isThemeDark}),
    watch: {
      '$store.persist.d.isThemeDark'(v) {
        app.$vuetify.theme.isDark = v;
      },
      '$route.path'() {
        app.$api.main.initDatasets();
      },
    },
    render: h => h(App),
  });

  // init data for debug

  // eslint-disable-next-line no-underscore-dangle
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    window.__app = app;
  }

  // init app data

  plugins.forEach(plugin => {
    plugin.setVueInstance(app);
  });

  app.$store.app.toolbarLoading = true;

  // init request manager

  // Vue.prototype.$rm = new RequestManager(app);

  // TODO: init routing logic

  // app.$router.beforeEach((to, from, next) => {
  //   if (to.path !== '/login' && !isAuth) {
  //     next({ path: '/login' });
  //   } else if (to.path === '/login' && isAuth) {
  //     next({ path: '/' });
  //   } else {
  //     next();
  //   }
  // });

  // mount app

  app.$mount('#app');

  // init auth

  // async function checkKeycloak() {
  //   const controller = new AbortController();
  //   const timeoutId = setTimeout(() => controller.abort(), 5000);
  //   try {
  //     return (await window.fetch(initOptions.url, {
  //       mode: 'no-cors',
  //       cache: 'no-cache',
  //       signal: controller.signal,
  //     }))?.status === 0;
  //   } catch (e) {
  //     return false;
  //   } finally {
  //     clearTimeout(timeoutId);
  //   }
  // }

  // const isKeycloakWorks = await checkKeycloak();

  // if (!isKeycloakWorks) {
  //   app.$store.main.d.erroredKeycloak = true;
  //   return;
  // }

  await app.$api.auth.signIn();

  // init app logic

  try {
    app.$api.auth.init();

    await app.$api.back.fetchBusinessUnits();

    /**
     *  если бэкенд был запущен последний раз позже, чем мы кэшировали данные,
     *  то обновляем мета данные о регистрах и справочниках,
     *  иначе обрабатываем сырые мета данные регистров и справочников из кэша.
     */
    if (!app.$store.persist.s.registers || !app.$store.persist.s.directories) {
      await app.$api.back.fetchDatasets();
    } else {
      app.$api.main.processDatasets();
      app.$store.main.d.loadedDatasets = true;
    }

    app.$api.main.initDatasets();

    app.$store.main.d.isAppReady = true;
  } catch (e) {
    console.error('Errored starting app');
    console.error(e);
  }
})();
