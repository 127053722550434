import Vue from 'vue'
import VueRouter from 'vue-router'
import PagePipeline from '../ui/pages/PagePipeline.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../ui/pages/PageLogin.vue'),
  },
  {
    path: '/pipeline',
    name: 'PagePipeline',
    component: PagePipeline,
    meta: {
      mainBottomNavigation: true,
    },
  },
  {
    path: '/registers',
    name: 'Registers',
    component: () => import(/* webpackChunkName: "datasets" */ '../ui/pages/PageDatasets.vue'),
    meta: {
      datasetType: 'registers',
      mainBottomNavigation: true,
    },
  },
  {
    path: '/directories',
    name: 'Directories',
    component: () => import(/* webpackChunkName: "datasets" */ '../ui/pages/PageDatasets.vue'),
    meta: {
      datasetType: 'directories',
      mainBottomNavigation: true,
    },
  },
  {
    path: '/registers/:id',
    name: 'Register',
    components: {
      default: () => import(/* webpackChunkName: "dataset" */ '../ui/pages/PageDataset.vue'),
    },
    meta: {
      datasetType: 'registers',
    },
  },
  {
    path: '/directories/:id',
    name: 'Directory',
    components: {
      default: () => import(/* webpackChunkName: "dataset" */ '../ui/pages/PageDataset.vue'),
    },
    meta: {
      datasetType: 'directories',
    },
  },
  {
    path: '/registers/:id/:itemId',
    name: 'RegisterItem',
    component: () => import(/* webpackChunkName: "dataset-item" */ '../ui/pages/PageDatasetItem.vue'),
    meta: {
      datasetType: 'registers',
    },
  },
  {
    path: '/directories/:id/:itemId',
    name: 'DirectoryItem',
    component: () => import(/* webpackChunkName: "dataset-item" */ '../ui/pages/PageDatasetItem.vue'),
    meta: {
      datasetType: 'directories',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../ui/pages/PageSettings.vue'),
  },
  {
    path: '/dev',
    name: 'Dev',
    component: () => import(/* webpackChunkName: "dev" */ '../ui/pages/PageDev.vue'),
    meta: {
      isExcludedAuth: true,
    },
  },
  {
    path: '/dev/declaration-schema',
    name: 'DevDeclarationSchema',
    component: () => import(/* webpackChunkName: "dev-declaration-schema" */ '../ui/pages/PageDevDeclarationSchema.vue'),
    meta: {
      isExcludedAuth: true,
    },
  },
  {
    path: '/dev/inputs',
    name: 'DevInputs',
    component: () => import(/* webpackChunkName: "dev-inputs" */ '../ui/pages/PageDevInputs.vue'),
    meta: {
      isExcludedAuth: true,
    },
  },
  {
    path: '/dev/auth',
    name: 'DevAuth',
    component: () => import(/* webpackChunkName: "dev-auth" */ '../ui/pages/PageDevAuth.vue'),
    meta: {
      isExcludedAuth: true,
    },
  },
  {
    path: '/dev/auth-frame',
    name: 'DevAuthFrame',
    component: () => import(/* webpackChunkName: "dev-auth-frame" */ '../ui/pages/PageDevAuthFrame.vue'),
    meta: {
      isExcludedAuth: true,
    },
  },
  {
    path: '/',
    redirect: '/pipeline',
  },
  {
    path: '/*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.isExcludedAuth) {
    next();
    return;
  }
  const isSelectBusinessUnit = !!localStorage.getItem(process.env.VUE_APP_LOCAL_BUSINESS_UNIT);
  if (to.path !== '/login' && !isSelectBusinessUnit) {
    next({ path: '/login' });
  } else if (to.path === '/login' && isSelectBusinessUnit) {
    next({ path: '/' });
  } else {
    next();
  }
});

// TODO: scroll top

export default router;
