<template>
  <v-app-bar
      v-if="$route.path !== '/login'"
      app
      flat
      class="app-bar"
      :style="appTitleStyle"
  >
    <Logo/>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
      <v-btn
          v-for="link in links"
          :key="link.name"
          :to="link.to"
          class="link mr-2"
          text
      >
        {{ link.name }}
      </v-btn>
    </div>
    <v-spacer/>
    <v-btn
        v-if="$store.main.d.businessUnitCurrent"
        text
        class="font-weight-bold"
        @click="openPeriod"
    >{{ String($store.persist.d.periodMonth).padStart(2, '0') }}.{{ $store.persist.d.periodYear }}</v-btn>
    <v-btn
        v-if="$store.main.d.businessUnitCurrent"
        text
        @click="$store.main.d.isShowProfile = true"
    >{{$store.main.d.businessUnitCurrent['НаименованиеРабочее']}}</v-btn>
    <v-btn
        @click="openSettings"
        icon
        class="ml-2"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>
    <v-btn
        key="logout"
        v-if="$route.path !== '/login'"
        icon
        @click="signOut"
        class="ml-2"
    >
      <v-icon class="ml-1">mdi-logout</v-icon>
    </v-btn>

    <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="primary"
    />
  </v-app-bar>
</template>

<script>
import Logo from "@/ui/components/Logo.vue";
export default {
  name: 'AppBar',
  components: {Logo},
  data: () => ({
    links: [
      {
        name: 'Расчет',
        to: '/pipeline',
      },
      {
        name: 'Регистры',
        to: '/registers',
      },
      {
        name: 'Справочники',
        to: '/directories',
      },
    ],
    showToolbar: false,
  }),
  computed: {
    appTitleStyle() {
      return {
        opacity: this.showToolbar ? 1 : 0,
        'overflow-x': this.$vuetify.breakpoint.mdAndUp ? 'none' : 'auto',
        'overflow-y': 'hidden',
      };
    },
    loading() {
      return this.$store.main.d.loadingDatasets;
    },
  },
  methods: {
    openSettings() {
      this.$store.main.d.isShowSettings = true;
    },
    signOut() {
      this.$api.app.openConfirmDialog({
        title: 'Выход из аккаунта',
        text: 'Вы уверены, что хотите выйти из аккаунта?',
        ok: () => {
          this.$api.auth.clearBusinessUnit();
          this.$api.auth.signOut();
          this.$router.push('/login').catch(() => {});
        },
      });
    },
    openPeriod() {
      this.$store.main.d.isShowPeriod = true;
    },
  },
  mounted() {
    this.showToolbar = true;
  },
}
</script>

<style scoped lang="scss">
.app-bar {
  transition: opacity .3s;
}

.link.v-btn--active {
  color: var(--v-primary-base)
}

.v-btn.dev {
  margin-top: -25px;
  margin-left: -50px;
  margin-right: 10px;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background: #fff;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
