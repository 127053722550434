<template>
  <v-app v-if="erroredKeycloak" class="fill-view-height">
    <transition name="fade" mode="out-in">
      <LayoutOneColumn>
        <Logo/>
        <DataError title="Не удалость проверить работоспобоность keycloak и инициировать авторизацию" error="Попробуйте перезагрузить страницу"/>
      </LayoutOneColumn>
    </transition>
  </v-app>
  <v-app v-else-if="isExcludedAuth" class="fill-view-height" style="min-width: 340px;">
    <v-main class="fill-height">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"/>
      </transition>
    </v-main>

    <ConfirmDialog/>
    <Snackbar/>
  </v-app>
  <v-app
      v-else-if="loaded && !errored && isAuth && (!$store.persist.s.businessUnits || !$store.persist.s.businessUnits.length)"
      class="fill-view-height">
    <PageNoBusinessUnits/>
  </v-app>
  <v-app
      v-else-if="loaded && !errored && isAuth"
      class="fill-view-height"
  >
    <AppBar v-if="$store.main.d.businessUnitCurrent"/>
    <AppBottomNavigation v-if="isShowBottomNavigation"/>

    <v-main class="fill-height">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"/>
      </transition>
    </v-main>

    <v-dialog
        v-model="$store.main.d.isShowCalc"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Расчет</span>
          <v-spacer/>
          <v-btn
              icon
              @click="$store.main.d.isShowCalc = false"
              class="toolbar__button-close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <DatasetsCalculator/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="$store.main.d.isShowGraph"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
      <v-card tile>
        <v-toolbar
            flat
            class="toolbar"
        >
          <v-toolbar-title>Граф наборов данных</v-toolbar-title>
          <v-spacer/>
          <v-btn
              icon
              @click="$store.main.d.isShowGraph = false"
              class="toolbar__button-close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <DatasetsMap @click="$store.main.d.isShowGraph = false"/>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="$store.main.d.isShowSettings"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
      <v-card tile>
        <v-toolbar
            flat
            class="toolbar"
        >
          <v-toolbar-title>Настройки</v-toolbar-title>
          <v-spacer/>
          <v-btn
              icon
              @click="$store.main.d.isShowSettings = false"
              class="toolbar__button-close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <PageSettings/>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="$store.main.d.isShowProfile"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
      <v-card tile>
        <v-toolbar
            flat
            class="toolbar"
        >
          <v-toolbar-title>Профиль</v-toolbar-title>
          <v-spacer/>
          <v-btn
              icon
              @click="$store.main.d.isShowProfile = false"
              class="toolbar__button-close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <PageProfile @clearBusinessUnit="$store.main.d.isShowProfile = false"/>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="$store.main.d.isShowPeriod"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
      <v-card tile>
        <v-toolbar
            flat
            class="toolbar"
        >
          <v-toolbar-title>Период</v-toolbar-title>
          <v-spacer/>
          <v-btn
              icon
              @click="$store.main.d.isShowPeriod = false"
              class="toolbar__button-close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <PagePeriod
            @save="savePeriod"
            @cancel="$store.main.d.isShowPeriod = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="$store.main.d.isShowExport"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
      <v-card tile>
        <v-toolbar
            flat
            class="toolbar"
        >
          <v-toolbar-title>Экспорт</v-toolbar-title>
          <v-spacer/>
          <v-btn
              icon
              @click="$store.main.d.isShowExport = false"
              class="toolbar__button-close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <PageExport
            @export="exportDataset"
            @cancel="$store.main.d.isShowExport = false"
        />
      </v-card>
    </v-dialog>

    <ConfirmDialog/>
    <Snackbar/>
  </v-app>

  <v-app v-else-if="loading" class="fill-view-height">
    <transition name="fade" mode="out-in">
      <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
      ></v-progress-linear>
    </transition>
  </v-app>

  <v-app v-else-if="!isAuth" class="fill-view-height">
    <transition name="fade" mode="out-in">
      <LayoutOneColumn>
        <Logo/>
        <DataError title="Вы не авторизованы" error="Для авторизации перезагрузите страницу"/>
      </LayoutOneColumn>
    </transition>
  </v-app>
  <v-app v-else-if="errored" class="fill-view-height">
    <transition name="fade" mode="out-in">
      <LayoutOneColumn>
        <Logo/>
        <DataError :error="error"/>
      </LayoutOneColumn>
    </transition>
  </v-app>
  <v-app v-else class="fill-view-height">
    <transition name="fade" mode="out-in">
      <LayoutOneColumn>
        <Logo/>
        <h1 class="my-4 text-center error--text">
          Critical error
        </h1>
      </LayoutOneColumn>
    </transition>
  </v-app>
</template>

<script>
import Logo from "@/ui/components/Logo.vue";
import DatasetsMap from "@/ui/components/DatasetsMap";
import ConfirmDialog from "@/ui/components/ConfirmDialog";
import Snackbar from "@/ui/components/Snackbar";
import DatasetsCalculator from "@/ui/components/DatasetsCalculator";
import AppBar from "@/ui/components/AppBar";
import AppBottomNavigation from "@/ui/components/AppBottomNavigation";
import DataError from "@/ui/components/DataError";
import PageSettings from "@/ui/pages/PageSettings";
import PageProfile from "@/ui/pages/PageProfile";
import PagePeriod from "@/ui/pages/PagePeriod";
import PageExport from "@/ui/pages/PageExport";
import LayoutOneColumn from "@/ui/layouts/LayoutOneColumn";
import PageNoBusinessUnits from "@/ui/pages/PageNoBusinessUnits";

export default {
  name: 'App',
  components: {
    PageNoBusinessUnits,
    LayoutOneColumn,
    PageExport,
    PagePeriod,
    PageProfile,
    PageSettings,
    AppBottomNavigation,
    AppBar,
    DatasetsCalculator,
    Snackbar,
    ConfirmDialog,
    DatasetsMap,
    Logo,
    DataError,
  },
  computed: {
    isExcludedAuth() {
      return this.$route.meta?.isExcludedAuth;
    },
    loading() {
      return this.$store.main.d.loadingBusinessUnits ||
          this.$store.main.d.loadingDatasets ||
          !this.$store.main.d.isAppReady;
    },
    loaded() {
      return this.$store.main.d.loadedBusinessUnits &&
          this.$store.main.d.loadedDatasets &&
          this.$store.main.d.isAppReady;
    },
    isAuth() {
      return this.$store.main.d.isAuth;
    },
    erroredKeycloak() {
      return this.$store.main.d.erroredKeycloak;
    },
    errored() {
      return this.$store.main.d.erroredBusinessUnits ||
          this.$store.main.d.erroredDatasets;
    },
    error() {
      if (this.$store.main.d.erroredBusinessUnits && this.$store.main.d.errorBusinessUnits) {
        return this.$store.main.d.errorBusinessUnits;
      }
      if (this.$store.main.d.erroredDatasets && this.$store.main.d.errorDatasets) {
        return this.$store.main.d.errorDatasets;
      }
      return null;
    },
    isShowBottomNavigation() {
      return this.$route.meta.mainBottomNavigation &&
          this.$store.main.d.businessUnitCurrent &&
          this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    savePeriod({periodYear, periodMonth}) {
      if (this.$store.persist.d.periodYear === periodYear &&
          this.$store.persist.d.periodMonth === periodMonth) {
        this.$store.main.d.isShowPeriod = false;
        return;
      }
      this.$store.persist.d.periodYear = periodYear;
      this.$store.persist.d.periodMonth = periodMonth;
      this.$store.main.d.periodMonthYear = `${periodMonth}.${periodYear}`;
      this.$store.main.d.isShowPeriod = false;
    },
    exportDataset(periods) {
      this.$api.app.snackDemo();
      // this.$api.back.exportDatasetItems(this.$store.main.d.datasetExportId, this.$store.main.d.datasetExportName, periods);
    },
  },
};
</script>

<style scoped lang="scss">
.fill-view-height {
  //height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.toolbar {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.toolbar__button-close {
  margin-right: 0 !important;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
@import 'styles/style.sass'
</style>
