<template>
  <div class="layout-one-column__container justify-center align-items-center">
    <div class="layout-one-column__content my-2 mx-auto pa-2" :style="contentStyle">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutOneColumn',
  props: {
    maxWidth: {
      type: Number,
      required: false,
      default: 966,
    },
  },
  computed: {
    contentStyle() {
      return {
        '--layout-one-column__content__max-width': `${this.maxWidth}px`,
      };
    },
  },
};
</script>

<style scoped>
.layout-one-column__container {
  height: 100%;
  overflow: auto;
}
.layout-one-column__container > .layout-one-column__content {
  max-width: var(--layout-one-column__content__max-width);
}
</style>
