<template>
  <v-container>
    <div class="d-flex flex-column">
      <v-btn
          :loading="$store.main.calcSoftLoading"
          depressed
          class="my-1"
          @click="$api.back.pipelineStart()"
      >
        Запустить перерасчет регистров
      </v-btn>
      <v-btn
          :loading="$store.main.calcHardLoading"
          depressed
          class="my-1"
          @click="$api.back.pipelineStartForce()"
      >
        Запустить принудительный перерасчет регистров
      </v-btn>
      <div class="my-2 text-center">
        <div v-if="$store.main.calcResult" class="success--text">
          {{ $store.main.calcResult }}
        </div>
        <div v-else-if="$store.main.calcError" class="error--text">
          {{ $store.main.calcError }}
        </div>
        <div v-else>
          Нет данных о запуске перерасчета регистров
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'DatasetsCalculator',
};
</script>

<style scoped>

</style>
