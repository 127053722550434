<template>
  <LayoutOneColumn :maxWidth="600">
    <div class="mt-4">
      <v-row class="mx-0 mb-1">
        <v-col cols="4" class="font-weight-bold pa-0">
          <div class="font-weight-bold pt-2 mr-2">Год</div>
        </v-col>
        <v-col cols="8" class="py-0">
          <PeriodYearInput v-model="periodYear" ref="periodYearInput"/>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="4" class="font-weight-bold pa-0">
          <div class="font-weight-bold pt-2 mr-2">Месяц</div>
        </v-col>
        <v-col cols="8" class="py-0">
          <PeriodMonthInput v-model="periodMonth" ref="periodMonthInput"/>
        </v-col>
      </v-row>
      <v-row class="mx-0 mt-6">
        <v-col cols="4" class="font-weight-bold pa-0">
        </v-col>
        <v-col cols="8" class="py-0">
          <v-btn
              depressed
              color="primary"
              @click="save"
              :loading="$store.main.d.loadingDatasetItemSave"
          >Сохранить</v-btn>
          <v-btn
              text
              class="ml-2"
              @click="cancel"
              width="113"
          >Отмена</v-btn>
        </v-col>
      </v-row>
    </div>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from '@/ui/layouts/LayoutOneColumn';
import PeriodYearInput from "@/ui/components/PeriodYearInput";
import PeriodMonthInput from "@/ui/components/PeriodMonthInput";
export default {
  name: 'PagePeriod',
  components: {PeriodMonthInput, PeriodYearInput, LayoutOneColumn},
  data: () => ({
    periodYear: '',
    periodMonth: '',
  }),
  watch: {
    '$store.main.d.isShowPeriod': {
      immediate: true,
      handler(v) {
        if (!v) {
          return;
        }
        this.periodYear = this.$store.persist.d.periodYear;
        this.periodMonth = this.$store.persist.d.periodMonth;
      },
    },
  },
  methods: {
    save() {
      if (!this.$refs.periodYearInput.isValid() || !this.$refs.periodMonthInput.isValid()) {
        return;
      }
      this.$emit('save', {
        periodYear: this.periodYear,
        periodMonth: this.periodMonth,
      });
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>

</style>
