<template>
  <LayoutOneColumn :maxWidth="600">
    <v-switch
        v-model="$store.persist.d.isThemeDark"
        label="Темная тема"
    />
    <v-switch
        v-model="$store.persist.d.isShowAutoComplete"
        label="Показывать данные записей при вводе"
    />
    <v-switch
        v-model="$store.persist.d.isCreateNewItemAfterSaving"
        label="Создавать новую запись после сохранения"
    />
    <v-btn
        @click="removeLocalData"
        depressed
        color="error"
        class="mt-4"
    >Удалить локальные данные</v-btn>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from '@/ui/layouts/LayoutOneColumn';
export default {
  name: 'PageSettings',
  components: {LayoutOneColumn},
  methods: {
    removeLocalData() {
      this.$api.app.openConfirmDialog({
        title: 'Удаление локальных данных',
        text: 'Вы уверены, что хотите удалить данные из локального хранилища?',
        ok: () => {
          Object.keys(localStorage).filter(k => k.startsWith('gpn-ndd_')).forEach(k => localStorage.removeItem(k));
          window.location.reload();
        },
      });
    },
  },
};
</script>
