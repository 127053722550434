<template>
  <LayoutOneColumn :maxWidth="600">
    <div class="mt-4">
      <v-row class="mx-0">
        <h3>Начало периода</h3>
      </v-row>
      <v-row class="mx-0 mb-1">
        <v-col cols="4" class="font-weight-bold pa-0">
          <div class="font-weight-bold pt-2 mr-2">Год</div>
        </v-col>
        <v-col cols="8" class="py-0">
          <PeriodYearInput v-model="startYear" ref="startYearInput"/>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="4" class="font-weight-bold pa-0">
          <div class="font-weight-bold pt-2 mr-2">Месяц</div>
        </v-col>
        <v-col cols="8" class="py-0">
          <PeriodMonthInput v-model="startMonth" ref="startMonthInput"/>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <h3>Конец периода</h3>
      </v-row>
      <v-row class="mx-0 mb-1">
        <v-col cols="4" class="font-weight-bold pa-0">
          <div class="font-weight-bold pt-2 mr-2">Год</div>
        </v-col>
        <v-col cols="8" class="py-0">
          <PeriodYearInput v-model="endYear" ref="endYearInput"/>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="4" class="font-weight-bold pa-0">
          <div class="font-weight-bold pt-2 mr-2">Месяц</div>
        </v-col>
        <v-col cols="8" class="py-0">
          <PeriodMonthInput v-model="endMonth" ref="endMonthInput"/>
        </v-col>
      </v-row>
      <v-row class="mx-0 mt-6">
        <v-col cols="4" class="font-weight-bold pa-0">
        </v-col>
        <v-col cols="8" class="py-0">
          <v-btn
              depressed
              color="primary"
              @click="exportData"
              :loading="$store.main.d.loadingDatasetItemSave"
          >Экспортировать</v-btn>
          <v-btn
              text
              class="ml-2"
              @click="cancel"
              width="113"
          >Отмена</v-btn>
        </v-col>
      </v-row>
    </div>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from '@/ui/layouts/LayoutOneColumn';
import PeriodYearInput from "@/ui/components/PeriodYearInput";
import PeriodMonthInput from "@/ui/components/PeriodMonthInput";
export default {
  name: 'PageExport',
  components: {PeriodMonthInput, PeriodYearInput, LayoutOneColumn},
  data: () => ({
    startYear: '',
    startMonth: '',
    endYear: '',
    endMonth: '',
  }),
  watch: {
    '$store.main.d.isShowExport': {
      immediate: true,
      handler(v) {
        if (!v) {
          return;
        }
        this.startYear = this.$store.persist.d.periodYear;
        this.startMonth = this.$store.persist.d.periodMonth;
        this.endYear = this.$store.persist.d.periodYear;
        this.endMonth = this.$store.persist.d.periodMonth;
      },
    },
  },
  methods: {
    exportData() {
      if (!this.$refs.startYearInput.isValid() ||
          !this.$refs.startMonthInput.isValid() ||
          !this.$refs.endYearInput.isValid() ||
          !this.$refs.endMonthInput.isValid()) {
        return;
      }
      this.$emit('export', {
        startYear: this.startYear,
        startMonth: this.startMonth,
        endYear: this.endYear,
        endMonth: this.endMonth,
      });
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>

</style>
