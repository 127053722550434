<template>
  <div
      class="d-flex"
      style="align-items: center; width: 100vw; height: 100vh;"
  >
    <v-card
        :tile="$vuetify.breakpoint.smAndDown"
        :elevation="$vuetify.breakpoint.smAndDown ? 0 : 1"
        class="mx-auto"
        style="max-width: 600px; overflow-y: auto; max-height: 100%; width: inherit; transition: all .2s;"
    >
      <div class="py-8">
        <div class="d-flex align-center">
          <Logo class="mx-8"/>
          <v-spacer/>
          <v-btn
              key="logout"
              icon
              @click="signOut"
              class="mr-8"
          >
            <v-icon class="ml-1">mdi-logout</v-icon>
          </v-btn>
        </div>
        <h2 class="my-4 text-center">Выбор бизнес-единицы не доступен</h2>
      </div>
    </v-card>
  </div>
</template>

<script>
import Logo from '@/ui/components/Logo.vue';
export default {
  name: 'PageNoBusinessUnits',
  components: {Logo},
  methods: {
    signOut() {
      this.$api.auth.signOut();
    },
  },
};
</script>
