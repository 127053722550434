<template>
  <LayoutOneColumn :maxWidth="600">
    <div
        class="d-flex flex-column"
    >
      <h2 class="mx-3">Данные запуска расчета</h2>
      <v-card
          class="px-8 pt-4 pb-4 ma-3"
          outlined
      >
        <h3 class="mb-2">Параметры запуска</h3>
        <div class="mt-5">
          <v-row class="mx-0 mb-1">
            <v-col cols="4" class="font-weight-bold pa-0">
              <div class="font-weight-bold pt-2 mr-2">Бизнес-единица</div>
            </v-col>
            <v-col cols="8" class="pa-0">
              <div class="pt-2">{{businessUnitCurrent['НаименованиеСокращённое']}}</div>
<!--              <BizUnitInput-->
<!--                  :items="$store.persist.s.businessUnits"-->
<!--                  v-model="businessUnitCurrent"-->
<!--                  :disabled="true"-->
<!--              />-->
            </v-col>
          </v-row>
          <v-row class="mx-0 mb-1">
            <v-col cols="4" class="font-weight-bold pa-0">
              <div class="font-weight-bold pt-2 mr-2">Год</div>
            </v-col>
            <v-col cols="8" class="pa-0">
              <PeriodYearInput v-model="periodYear" ref="periodYear"/>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col cols="4" class="font-weight-bold pa-0">
              <div class="font-weight-bold pt-2 mr-2">Месяц</div>
            </v-col>
            <v-col cols="8" class="pa-0">
              <PeriodMonthInput v-model="periodMonth" ref="periodMonth"/>
            </v-col>
          </v-row>
        </div>
        <v-switch
            class="mt-6"
            v-model="isForceCalc"
            label="Принудительный перерасчет"
            color="primary"
        />
        <div>
          <v-btn
              :loading="loading"
              @click="pipelineStart"
              depressed
              color="primary"
              block
              :disabled="!canStartPipeline && !loading"
          >
            Запустить перерасчет
          </v-btn>
        </div>
        <div class="mt-4 mb-2">
          <div v-if="$store.main.d.calcResult" class="success--text">
            {{ $store.main.d.calcResult }}
          </div>
          <div v-else-if="$store.main.d.calcError" class="error--text">
            {{ $store.main.d.calcError }}
          </div>
          <div v-else-if="loading">
            Перерасчет в процессе. Ожидайте
          </div>
          <div v-else-if="!canStartPipeline">
            Нельзя запустить перерасчет, так как он уже запущен
          </div>
          <div v-else>
            Нет данных о запуске перерасчета
          </div>
        </div>
      </v-card>
    </div>
    <div
        v-if="globalConfigLoaded"
        class="d-flex flex-column mx-auto"
        style="max-width: 600px;"
    >
      <div class="d-flex">
        <h2 class="mx-3">Шаги перерасчета</h2>
        <v-spacer/>
        <v-btn
            class="mr-3"
            depressed
            @click="$store.main.d.isShowGraph = true"
        >Показать граф</v-btn>
      </div>

      <div v-if="$store.main.d.loadedPipelineData || pipelineData">
        <v-scroll-y-transition group>
          <v-card
              v-for="(item, i) of pipelineData"
              :key="`pipelineStatus-item-${i}`"
              class="px-8 pt-4 pb-4 ma-3"
              outlined
          >
            <h3 class="mb-2">{{item['НазваниеШага']}}</h3>
            <div class="mb-2">{{item['ОписаниеШага']}}</div>
            <div class="mb-2">{{item['ОписаниеСтатуса']}}</div>
            <div style="font-size: 10px">Статус: {{item['Статус']}}</div>
            <div style="font-size: 10px">Идентификатор Запуска Пайплайна: {{item['ИдентификаторЗапускаПайплайна']}}</div>
            <div style="font-size: 10px">Время Запуска Пайплайна: {{item['ВремяЗапускаПайплайна']}}</div>
            <div style="font-size: 10px">Время Запуска Шага: {{item['ВремяЗапускаШага']}}</div>
          </v-card>
        </v-scroll-y-transition>
      </div>

      <template v-if="!$store.main.d.loadedPipelineData && !pipelineData">
<!--        <v-expand-transition>-->
        <div
            v-if="$store.main.d.loadingPipelineData"
            class="pipeline-loading"
            :class="$vuetify.theme.isDark ? 'theme--dark' : null"
        >
          Загрузка данных...
        </div>
        <div
            v-else-if="$store.main.d.erroredPipelineData"
            class="my-4">
          <h1 class="text-center font-weight-light error--text">
            Произошла ошибка при получении данных
          </h1>
          <h5
              v-if="$store.main.d.errorPipelineData"
              class="text-center"
          >{{$store.main.d.errorPipelineData}}</h5>
        </div>
<!--        </v-expand-transition>-->
      </template>
    </div>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from '@/ui/layouts/LayoutOneColumn';
import BizUnitInput from "@/ui/components/BizUnitInput";
import PeriodYearInput from "@/ui/components/PeriodYearInput";
import PeriodMonthInput from "@/ui/components/PeriodMonthInput";
export default {
  name: 'PagePipeline',
  components: {PeriodMonthInput, PeriodYearInput, BizUnitInput, LayoutOneColumn},
  data: () => ({
    isForceCalc: false,
    businessUnitCurrent: null,
    periodYear: null,
    periodMonth: null,
    polling: null,
    canStartPipeline: false,
    globalConfigLoaded: false,
    pipelineData: null,
  }),
  watch: {
    '$store.main.d.calcSoftLoading': {
      handler() {
        if (!this.$store.main.d.calcSoftLoading && !this.$store.main.d.calcHardLoading) {
          this.canStartPipeline = true;
        }
      },
    },
    '$store.main.d.calcHardLoading': {
      handler() {
        if (!this.$store.main.d.calcSoftLoading && !this.$store.main.d.calcHardLoading) {
          this.canStartPipeline = true;
        }
      },
    },
    '$store.main.d.loadedPipelineData': {
      immediate: true,
      handler(v) {
        if (!v) {
          return;
        }
        this.pipelineData = this.$store.main.s.pipelineStatusData;
      },
    },
  },
  async created() {
    this.businessUnitCurrent = this.$store.main.d.businessUnitCurrent;
    this.periodYear = this.$store.persist.d.periodYear;
    this.periodMonth = this.$store.persist.d.periodMonth;
    this.canStartPipeline = true;
  },
  computed: {
    loading() {
      return this.$store.main.d.calcSoftLoading || this.$store.main.d.calcHardLoading;
    },
  },
  methods: {
    /**
     * Запуск пайплайна (перерасчета декларации)
     * @returns {Promise<void>}
     */
    async pipelineStart() {
      this.$store.main.d.calcResult = null;
      this.$store.main.d.calcError = null;

      if (!this.$refs.periodYear.isValid()) {
        this.$store.main.d.calcError = 'Введен некорректный год в параметрах запуска';
        return;
      }
      if (!this.$refs.periodMonth.isValid()) {
        this.$store.main.d.calcError = 'Введен некорректный месяц в параметрах запуска';
        return;
      }

      try {

        this.$store.main.d.calcSoftLoading = false;
        this.$store.main.d.calcHardLoading = false;
        this.$store.main.d.calcResult = null;
        this.$store.main.d.calcError = null;

        this.$store.main.d.loadedPipelineData = false;
        this.$store.main.d.loadingPipelineData = true;
        this.$store.main.d.errorPipelineData = null;
        this.$store.main.d.erroredPipelineData = false;

        if (this.isForceCalc) {
          this.$store.main.d.calcHardLoading = true;
        } else {
          this.$store.main.d.calcSoftLoading = true;
        }

        this.$api.app.snackDemo();
        await this.$api.app.wait2sec();

        this.$store.main.d.loadedPipelineData = true;
        this.$store.main.d.loadingPipelineData = false;

        if (this.isForceCalc) {
          this.$store.main.d.calcResult = 'Перерасчет успешно выполнен в принудительном режиме';
        } else {
          this.$store.main.d.calcResult = 'Перерасчет успешно выполнен в обычном режиме';
        }

        if (this.isForceCalc) {
          this.$store.main.d.calcHardLoading = false;
        } else {
          this.$store.main.d.calcSoftLoading = false;
        }

      } catch (e) {
        console.error(e);
        this.$store.main.d.calcError = 'Произошла ошибка при перерасчете';
      }
    },

    /**
     * Получение данных о конфигурации пайплайна
     * @returns {Promise<void>}
     */
    async fetchGlobalConfig() {
      try {
        const configJson = await (await this.$rm.fetch(`${this.$api.back.baseUrl}/global_conf/read`)).json();

        // if (configJson.BU) {
        //   this.businessUnitCurrent = this.$store.persist.s.businessUnits.find(el => el['КодБЕ'] === configJson.BU);
        // }
        // if (!this.businessUnitCurrent) {
        //   this.businessUnitCurrent = this.$store.main.d.businessUnitCurrent;
        // }
        this.businessUnitCurrent = this.$store.main.d.businessUnitCurrent;

        if (configJson.YEAR) {
          this.periodYear = configJson.YEAR.toString();
        }
        if (!this.periodYear) {
          this.periodYear = this.$store.persist.d.periodYear;
        }

        if (configJson.MONTH) {
          this.periodMonth = configJson.MONTH.toString();
        }
        if (!this.periodMonth) {
          this.periodMonth = this.$store.persist.d.periodMonth;
        }

        this.globalConfigLoaded = true;
      } catch (e) {
        console.error(e);
        this.$store.main.d.calcError = 'Произошла ошибка при получении глобальной конфигурации';
      }
    },

    /**
     * Проверка возможности запуска пайплайна
     * @returns {Promise<void>}
     */
    async checkPipeline() {
      try {
        // this.$store.main.d.calcResult = null;
        // this.$store.main.d.calcError = null;
        const checkJson = await (await this.$rm.fetch(`${this.$api.back.baseUrl}/check_pipeline_status/`)).json();
        this.canStartPipeline = checkJson?.status === 'Success';
        if (!this.canStartPipeline) {
          this.$api.back.fetchPipelineData();
        }
        // if (checkJson?.status === 'Error') {
        //   if (checkJson?.message) {
        //     console.error(checkJson?.message);
        //   }
        //   this.$store.main.d.calcError = checkJson?.message || 'Произошла ошибка при проверке статуса перерасчета';
        // }
      } catch (e) {
        console.error(e);
        this.$store.main.d.calcError = 'Произошла ошибка при проверке статуса перерасчета';
      }
    },

    /**
     * Получение данных о пайплайне каждые 5 секунд
     * @returns {Promise<void>}
     */
    async startPoll() {
      if (this.polling) {
        this.stopPoll();
      }
      this.polling = setInterval(() => {
        this.checkPipeline();
        if (this.loading) {
          this.$api.back.fetchPipelineData();
        }
      }, 5000);
      await this.checkPipeline();
    },

    stopPoll() {
      clearInterval(this.polling);
    },
  },
  beforeDestroy () {
    this.stopPoll();
  },
};
</script>

<style scoped>
.pipeline-loading {
  height: 100%;
  background-color: #fff;
  font-size: 0.875rem;
  line-height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
}
.theme--dark.pipeline-loading {
  background-color: #1E1E1E;
  color: rgba(255, 255, 255, 0.5);
}
</style>
