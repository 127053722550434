<template>
  <div class="main-panel">
  <div class="scheme-drawer-wrapper" ref="wrapper">
    <div
        v-if="blocks"
        class="scheme-drawer-container mx-auto"
        ref="container"
        :style="containerStyle"
    >
      <svg
          class="svg-container"
          ref="connections"
          :width="viewBox.w"
          :height="viewBox.h"
      >
        <defs>
          <marker
              id="arrowhead"
              markerWidth="10"
              markerHeight="7"
              refX="9.5"
              refY="3.5"
              orient="auto"
          >
            <polygon
                :fill="$vuetify.theme.isDark ? '#393939' : 'black'"
                points="0 0, 10 3.5, 0 7"
            />
          </marker>
        </defs>
        <template v-if="linksLines">
          <line
              v-for="(linkLine, i) of linksLines"
              :key="`link-line-${i}`"
              v-bind="linkLine"
              :stroke="$vuetify.theme.isDark ? '#393939' : 'black'"
              marker-end="url(#arrowhead)"
          ></line>
        </template>
      </svg>
      <div :style="blockContainerStyle">
        <v-card
            v-for="block of blocks"
            :key="block.key"
            outlined
            class="px-2 py-1"
            :style="blockStyle"
            :ref="`block-${block.key}`"
            @click="openBlock(block)"
        >
          <div class="d-flex">
            <span class="caption">{{ block.typeNls }}</span>
            <v-spacer/>
<!--            mdi-checkbox-marked-circle -->
<!--            mdi-checkbox-blank-circle-outline-->
<!--            <v-icon small color="success">mdi-checkbox-blank-circle</v-icon>-->
          </div>
          <h3 style="line-height: 0.9;" class="mb-1">{{ block.label }}</h3>
        </v-card>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "DatasetsMap",
  data: () => ({
    viewBox: {
      x: 0,
      y: 0,
      w: 985,
      h: 1250,
    },
    blocksPositions: null,
    blockWidth: 165,
    blockHeight: 50.84,
    blocks: null,
    blockStyle: {
      width: '165px',
      userSelect: 'none',
      cursor: 'pointer',
      position: 'absolute',
      // opacity: 0.2,
    },
    links: [],
    connectionsMap: null,
    linksLines: null,
  }),
  computed: {
    containerStyle() {
      return {
        width: `${this.viewBox.w}px`,
        height: `${this.viewBox.h}px`
      };
    },
    blockContainerStyle() {
      return {
        width: `${this.viewBox.w}px`,
        height: `${this.viewBox.h}px`,
        position: "absolute",
        userSelect: "none"
      };
    },
  },
  created() {
    this.calc();
    this.$nextTick(() => {
      this.blocks.forEach(el => {
        try {
          this.$refs[`block-${el.key}`][0].$el.style.left = this.blocksPositions[el.key][0] + 'px';
          this.$refs[`block-${el.key}`][0].$el.style.top = this.blocksPositions[el.key][1] + 'px';
        } catch (e) {
          console.error(e);
        }
      });
      this.linksLines = this.calcLinksLines();
    });
  },
  methods: {
    openBlock(block) {
      this.$router.push(`/${block.type === 'register' ? 'registers' : 'directories'}/${block.key}`);
      this.$emit('click', block.key);
    },
    calc() {
      const datasets = [
        ...this.$store.main.s.registers.map(el => ({...el, datasetType: 'register'})),
        ...this.$store.main.s.directories.map(el => ({...el, datasetType: 'directory'})),
      ];

      const connections = [];
      datasets.forEach(d => {
        Object.entries(d.FieldName).map(([fieldKey, fieldValue]) => {
          if (!fieldValue.ForeignKey) {
            return;
          }
          const [datasetFrom, fieldFrom] = fieldValue.ForeignKey.split('.');
          connections.push({
            datasetFrom,
            fieldFrom,
            datasetTo: d.id,
            fieldTo: fieldKey,
          });
        });
      });

      const datasetsWithConnections = datasets.filter(d => connections.find(c => c.datasetFrom === d.id || c.datasetTo === d.id))
      const datasetsWithoutConnections = datasets.filter(d => !connections.find(c => c.datasetFrom === d.id || c.datasetTo === d.id))
      const datasetsWithoutFromConnections = datasets.filter(d => !connections.find(c => c.datasetFrom === d.id));
      const datasetsWithoutToConnections = datasets.filter(d => !connections.find(c => c.datasetTo === d.id)).filter(d => connections.find(c => c.datasetFrom === d.id));

      const levels = [
        {
          datasets: datasetsWithoutConnections,
          level: 0,
        },
        {
          datasets: datasetsWithoutToConnections,
          level: 1,
        },
      ];

      let currentLevel = 1;
      while (true) {
        const datasetsByLevel = levels.filter(level => level.level <= currentLevel).map(level => level.datasets).flat();
        const datasetsKeysByLevel = datasetsByLevel.map(d => d.id);
        const datasetsByCurrentLevel = levels.find(level => level.level === currentLevel).datasets;
        const datasetsKeysByCurrentLevel = datasetsByCurrentLevel.map(d => d.id);
        const datasetsWithFromConnectionsByCurrentLevel = datasets
            .filter(d => !datasetsKeysByLevel.includes(d.id))
            .filter(d =>
              connections.find(c =>
                  c.datasetTo === d.id &&
                  datasetsKeysByCurrentLevel.find(k => k === c.datasetFrom && k !== d.id)
              ));
        if (!datasetsWithFromConnectionsByCurrentLevel.length) {
          break;
        }
        currentLevel += 1;
        levels.push({
          datasets: datasetsWithFromConnectionsByCurrentLevel,
          level: currentLevel,
        });
        if (currentLevel > 50) {
          console.error('Cycle calc error');
          return [];
        }
      }

      this.connectionsMap = {};
      connections.forEach(c => {
        if (!this.connectionsMap[c.datasetFrom]) {
          this.connectionsMap[c.datasetFrom] = [c.datasetTo];
          return;
        }
        if (!this.connectionsMap[c.datasetFrom].includes(c.datasetTo)) {
          this.connectionsMap[c.datasetFrom].push(c.datasetTo);
        }
      });

      const w = 165;
      const h = 55;
      const offsetX = 50;
      const offsetY = 50;
      const gapX = 15;
      const gapY = 50;
      const wMax = this.viewBox.w;

      this.blocksPositions = {};
      let currentY = offsetY;
      levels.forEach(level => {
        let xIndex = 0;
        let yIndex = 0;
        level.datasets.forEach(d => {
          this.blocksPositions[d.id] = [offsetX + xIndex * (gapX + w), currentY + yIndex * (gapY + h)];
          xIndex += 1;
          if (offsetX + (xIndex + 1) * (gapX + w) > wMax) {
            xIndex = 0;
            yIndex += 1;
          }
        });
        currentY += (yIndex + 1) * (gapY + h);
      });

      this.viewBox.h = currentY + offsetY;

      this.blocks = Object.keys(this.blocksPositions).map(k => {
        const d = datasets.find(d => d.id === k);
        return {
          key: k,
          label: d.name,
          type: d.datasetType,
          typeNls: d.datasetType === 'register' ? 'Регистр' : 'Справочник',
        };
      });
    },
    calcLinksLines() {
      if (!this.blocks) {
        return [];
      }
      const res = [];
      this.blocks.forEach(block => {
        if (!this.connectionsMap[block.key]) {
          return;
        }
        this.connectionsMap[block.key].forEach(connection => {
          const target = this.blocks.find(el => el.key === connection);

          const blockWidth = this.blockWidth;
          const blockHeight = this.blockHeight;

          // const sourceEl = this.$refs[`block-${block.key}`][0].$el;
          // const targetEl = this.$refs[`block-${target.key}`][0].$el;
          // const targetRect = targetEl.getBoundingClientRect();
          // const sourceRect = sourceEl.getBoundingClientRect();
          // const targetBlockWidth = targetRect.width;
          // const targetBlockHeight = targetRect.height;
          // const sourceBlockWidth = sourceRect.width;
          // const sourceBlockHeight = sourceRect.height;

          const fromPosition = this.blocksPositions[block.key];
          const toPosition = this.blocksPositions[target.key];
          const angle = Math.atan2(Math.abs(toPosition[1] - fromPosition[1]), Math.abs(toPosition[0] - fromPosition[0]));
          const angleBlock = Math.atan2(blockHeight, blockWidth);
          let w, h;
          if (angle < angleBlock) {
            w = blockWidth / 2;
            h = Math.tan(angle) * (blockWidth / 2);
          } else if (angle > angleBlock) {
            w = (1 / Math.tan(angle)) * (blockHeight / 2);
            h = blockHeight / 2;
          } else {
            w = blockWidth / 2;
            h = blockHeight / 2;
          }
          const line = {
            x1: fromPosition[0] + blockWidth / 2,
            y1: fromPosition[1] + blockHeight / 2,
            x2: toPosition[0] + blockWidth / 2,
            y2: toPosition[1] + blockHeight / 2,
          };
          if (fromPosition[0] > toPosition[0]) {
            line.x1 -= w;
            line.x2 += w;
          }
          if (fromPosition[1] > toPosition[1]) {
            line.y1 -= h;
            line.y2 += h;
          }
          if (fromPosition[0] < toPosition[0]) {
            line.x1 += w;
            line.x2 -= w;
          }
          if (fromPosition[1] < toPosition[1]) {
            line.y1 += h;
            line.y2 -= h;
          }
          res.push(line);
        });
      });
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-panel {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.scheme-drawer-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.scheme-drawer-container {
  position: relative;
}
.svg-container {
  position: absolute;
}

.v-card {
  transition: 0.3s;
  opacity: 1;
}
.v-card:hover {
  background-color: var(--v-primary-base);
  opacity: 0.87;
  color: #fff;
}
</style>
