<template>
  <LayoutOneColumn v-if="profile" :maxWidth="600">
    <v-row
        v-for="item of items"
        :key="item.key"
    >
      <v-col cols="4" class="font-weight-bold profile-item">{{item.name}}</v-col>
      <v-col cols="8" class="profile-item">{{profile[item.key]}}</v-col>
    </v-row>
    <v-btn
        @click="clearBusinessUnit"
        depressed
        color="primary"
        class="mt-6"
    >Сменить бизнес-единицу</v-btn>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from '@/ui/layouts/LayoutOneColumn';
export default {
  name: 'PageProfile',
  components: {LayoutOneColumn},
  data: () => ({
    items: [
      {
        key: 'НаименованиеСокращённое',
        name: 'Сокращённое наименование',
      },
      {
        key: 'НаименованиеРабочее',
        name: 'Рабочее наименование',
      },
      {
        key: 'НаименованиеПолное',
        name: 'Полное наименование',
      },
      {
        key: 'КодБЕ',
        name: 'Код',
      },
      {
        key: 'КПП',
        name: 'КПП',
      },
      {
        key: 'ИННЮЛ',
        name: 'ИННЮЛ',
      },
      {
        key: 'ОКТМО',
        name: 'ОКТМО',
      },
    ],
  }),
  computed: {
    profile() {
      return this.$store.main.d.businessUnitCurrent;
    },
  },
  methods: {
    clearBusinessUnit() {
      this.$emit('clearBusinessUnit');
      setTimeout(() => {
        this.$api.auth.clearBusinessUnit();
        this.$router.push('/login').catch(() => {});
      },300);
    },
  },
};
</script>

<style scoped>
.profile-item {
  line-height: 1;
}
</style>
