export default class AppPlugin {
  constructor(key, items, needCreateInstance = false) {
    this.key = key;
    this.needCreateInstance = needCreateInstance;
    this.vueApp = null;
    this.installed = false;
    this.items = items;
    this.DELAY_WAIT = 10;
  }

  async install(Vue, options) {
    Vue.prototype[`$${this.key}`] = Object.fromEntries(
      await Promise.all(this.items.map(async k => {
        let item = (await import(`@/${this.key}/${k}`)).default;
        if (this.needCreateInstance) {
          item = new item();
        }
        return [k, item];
      }))
    );
    this.installed = true;
    if (this.needCreateInstance && this.vueApp) {
      this.settingApp();
    }
  }

  async wait() {
    await new Promise(r => {
      const interval = setInterval(() => {
        if (this.installed) {
          clearInterval(interval);
          r();
        }
      }, this.DELAY_WAIT);
    })
  }

  settingApp() {
    const field = `$${this.key}`;
    this.items.forEach(k => {
      this.vueApp[field][k].$setApp(this.vueApp);
    });
  }

  setVueInstance(app) {
    this.vueApp = app;
    if (this.needCreateInstance && this.installed) {
      this.settingApp();
    }
  }
}
